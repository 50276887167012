<template>
  <div class="homeImage-cover position-relative">
    <Swiper
      v-if="coverImages.length > 0"
      :id="`post-slider-homeSlider-landing`"
      class="slider-homeSlider-landing h-100 position-relative"
      :options="swiperOptions"
      :pagination="true"
      :navigation="true"
    >
      <swiperSlide v-for="(image, key1) in coverImages" :key="key1">
        <section
          :style="{
            'background-size': '100%',
            'background-position': 'center 10%',
            'background-image': 'url(' + image + ')',
          }"
          class="slide-bg w-100 h-100 position-absolute"
        ></section>
        <b-container class="h-100">
          <b-row class="h-100 align-items-center">
            <b-col cols="12" md="6">
              <div>
                <h1 class="main-heading font-weight-bold">
                  {{ $t("main.apothecary_Market") }}
                </h1>
                <p class="main-text font-size-22">
                  {{ $t("main.apothecary_Market_brief") }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </swiperSlide>
    </Swiper>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      image: require("@/assets/images/ibbil/homePage/bg.png"),
      swiperOptions: {
        spaceBetween: 1,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        pagination: {
          clickable: true,
          el: ".slider-homeSlider-landing .swiper-pagination",
          type: "bullets",
        },
        // Navigation arrows
        navigation: {
          nextEl: ".landing-swiper-button-next",
          prevEl: ".landing-swiper-button-prev",
        },
      },
    };
  },
  computed: {
    coverImages() {
      return this.$store.getters["Setting/appSettings"].cover_page_imgs;
    },
  },
  watch: {},
  methods: {},
  mounted() {},
};
</script>
<style lang="scss">
.swiper-container-horizontal {
  height: 100%;
}

.header-cover {
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  height: 100%;
  display: flex;
  align-items: center;
  div {
    width: 60%;
    &:lang(en) {
      width: 80%;
    }
    @media (max-width: 992px) {
      width: 100%;
    }
  }
}
.homeImage-cover {
  height: 25rem;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .slide-bg {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent
        linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) 0% 0%
        no-repeat padding-box;
    }
  }
}

@media (max-width: 576px) {
  .homeImage-cover {
    background-size: cover !important;
    background-position: center bottom !important;
  }
}
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #fff;
  margin: 0 3px;
  border-radius: 50%;
  cursor: pointer;
}
.swiper-pagination-bullet-active {
  background-color: var(--iq-primary);
}
.swiper-button-next {
  cursor: pointer;
  left: 50px;
}
.swiper-button-prev {
  right: 50px;
}
.swiper-button-next img {
  transform: rotateY(180deg);
}
.swiper-button-prev.swiper-button-no-rotate img {
  transform: rotateY(0) !important;
}
.arrows img {
  width: 35px;
}

@media (max-width: 992px) {
  .header-cover {
    max-width: 100% !important;
    text-align: center;
    padding: 0 15px 0 15px;
  }
  .header-cover h1 {
    font-size: 28px !important;
  }
  .header-cover p {
    font-size: 20px !important;
  }
}
/*style main page */

.mainImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.main-heading {
  color: #d5d5d5 !important;
  font-size: 4rem !important;
  @media (max-width: 1400px) {
    font-size: 2rem !important;
  }
}
.main-text {
  color: #d5d5d5 !important;
  font-size: 1.75rem !important;
  line-height: 40px;
  @media (max-width: 1400px) {
    font-size: 1.5rem !important;
    line-height: 30px;
  }
}
</style>
