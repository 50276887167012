<template>
  <div>
    <homeSlider />
    <allStores />
  </div>
</template>
<script>
import homeSlider from "../components/homeSlider";

import allStores from "@/modules/servicePages/marketplace/views/allStores/allMarketplaces";
export default {
  name: "Home",
  components: { homeSlider, allStores },

  data() {
    return {};
  },
};
</script>
